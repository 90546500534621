$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  background-color: $background-main;
  height: 100%;
  width: 100vw;
  position: relative;
}

.wrapper {
  min-height: 563px;
  height: 100%;
  position: relative;

  @media screen and (min-width: $breakpoint_l) {
    min-height: 720px;
  }
}

.content {
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  opacity: 0;
  animation: fadeIn 0.7s ease forwards;
  animation-delay: 0s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  width: 100%;
  max-width: 900px;
  margin-bottom: 16px;
  color: $lights-high;
  text-shadow: 0px 2px 4px #000000;

  @include text-h2;

  @media screen and (min-width: $breakpoint_ml) {
    @include text-h1;
  }

  @media screen and (min-width: $breakpoint_l) {
    @include text-h1;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include text-h1;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    @include text-h1;
  }

  & > p > strong {
    color: $accent-default;
    font-style: normal;
  }
}

.link {
  @include text-h6;

  color: $accent-default;
  text-transform: unset;
  text-decoration: underline;
  text-shadow: 0 2px 4px #000000;
}

.mediaWrapper {
  width: 100%;
  position: absolute;
  min-height: 563px;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  @media screen and (min-width: $breakpoint_l) {
    min-height: 720px;
  }
}

.image {
  width: 100%;
  height: 100%;
}

.video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 9999;
}

.videoControls {
  position: absolute;
  visibility: hidden;
}

.videoControlButton {
  display: flex;
  color: $lights-high;

  @extend %button-reset;

  &:hover {
    opacity: 0.8;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  > svg {
    font-size: 32px;
  }
}

.searchBarWrapper {
  padding: 20px 0;
  z-index: 10;
}
