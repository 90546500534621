$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.carouselContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.carouselTrack {
  display: flex;
  animation: scroll 20s linear infinite;
  will-change: transform;
}

.carouselSlide img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: $radius-1;
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  99.9% {
    transform: translate3d(calc(var(--scroll-distance) - 0.05px), 0, 0);
  }
  100% {
    transform: translate3d(calc(var(--scroll-distance) - 0.05px), 0, 0);
  }
}

.roundedImagesContainer {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  width: 250px;
  margin: auto;

}

.roundedImageWrapper {
  grid-area: 1 / 1 / 2 / 2;
  width: 100px;
  height: 100px;
  border-radius: 108px;
  overflow: hidden;
  border: 1.612px solid $border-default;
}

.roundedImageWrapper:nth-child(1) {
  justify-self: start;
}

.roundedImageWrapper:nth-child(2) {
  justify-self: center;
  z-index: 1;
}

.roundedImageWrapper:nth-child(3) {
  justify-self: end;
}

.roundedImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
