$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.container {
  width: 100%;
  background-color: $background-main;
  padding: $spacing-10;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: $breakpoint_l) {
    padding: $spacing-6;
  }
}

.content {
  max-width: 1220px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: $breakpoint_l) {
    max-width: 100%;
    padding: 0 $spacing-2;
  }
}

.title {
  @include text-h2;
  padding-bottom: $spacing-5;

  @media (max-width: $breakpoint_l) {
    padding-bottom: $spacing-3;
  }
}

.description {
  white-space: pre-line;
  padding-bottom: $spacing-8;
  @include text-body1Regular16;

  @media (max-width: $breakpoint_l) {
    font-size: 16px;
    padding-bottom: $spacing-4;
  }
}

.storeLinks {
  display: flex;
  justify-content: center;
  gap: $spacing-4;
  margin-bottom: $spacing-8;

  @media (max-width: $breakpoint_l) {
    flex-direction: column;
    align-items: center;
    gap: $spacing-3;
  }
}

.appScreenshots {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: $spacing-8;

}

.screenshot {
  position: relative;
  width: 220px;
  height: 400px;
  margin: 0 -20px;
  object-fit: contain;
  transition: transform 0.3s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);

  &:nth-child(2) {
    object-fit: fill;
    width: 240px;
    height: 460px;
  }
  &:nth-child(3) {
    width: 270px;
    height: 535px;
    object-fit: fill;
    z-index: 3;
  }
  &:nth-child(4) {
    object-fit: fill;
    width: 240px;
    height: 460px;
    z-index: 2;
  }

  @media (max-width: $breakpoint_l) {
    margin: 0 -40px;
    width: 160px;
    height: 320px;

    &:nth-child(1) {
      transform: translateX(20px);
      z-index: 1;
    }

    &:nth-child(2) {
      width: 180px;
      height: 360px;
      transform: translateX(10px);
      z-index: 2;
    }

    &:nth-child(3) {
      width: 200px;
      height: 400px;
      z-index: 3;
    }

    &:nth-child(4) {
      width: 180px;
      height: 360px;
      transform: translateX(-10px);
      z-index: 2;
    }
  }
}
