$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.card {
  display: flex;
  padding: $spacing-6 0;
  flex-direction: column;
  align-items: center;
  gap: $spacing-4;
  flex: 1 0 0;
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  max-width: 393px;
  border-radius: $radius-3;
  background: $background-default;

  @media (max-width: $breakpoint_l) {
    flex-direction: column;
    max-width: 350px;
  }
}

.imageContainer {
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
}

.textContainer {
  padding: 0 40px;
}

.title {
  color: $lights-high;
  text-align: center;

  @include text-h4;
}

.description {
  color: $lights-medium;
  text-align: center;

  @include text-body1Regular16;
}
