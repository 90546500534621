$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.container {
  position: relative;
  width: 100%;
  height: 730px;
  height: 100%;
  background-color: $background-main;
}

.carouselWrapper {
  width: 100%;
  height: 100%;
}

.carouselContainer {
  gap: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  width: fit-content;
}

.imageRow {
  height: 100%;
  display: flex;
}

.imageContainer {
  flex: 0 0 auto;
  margin-right: 10px; 
}

.image {
  border-radius: $spacing-2;
  height: 100%;
  width: 250px;
  object-fit: cover;
}
  
.overlay {
  position: absolute;
  inset: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0.2) 100%
    );
}

  .contentOverlay {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 10;
  }
  
  .title {
   @include text-h1;

   padding-bottom: $spacing-4;
   text-align: center;
  }
  
  @keyframes scrollRight {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  .scrollRight {
    animation-name: scrollRight;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .scrollLeft {
    animation-name: scrollLeft;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
