$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.videoContainer {
  background-color: $background-main;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 730px;
  overflow: hidden;
  height: 100%;

  &.withBackgroundImage,
  &[style*="background-image"] {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
    @media (max-width: $breakpoint_l) {
      background-size: cover;
    }
  }
}



.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  z-index: 0;
  pointer-events: none;

  @media (max-width: $breakpoint_l) {
    object-fit: cover;
  }
}

.contentOverlay {
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 2;
}

.contentOverlay--left {
  padding-left: 150px;
  justify-content: flex-start;
  align-items: center;
}

.contentOverlay--right {
  padding-right: 150px;
  justify-content: flex-end;
  align-items: center;
}

.buttonCustomStyles {
  padding-top: $spacing-8 !important;
  text-align: left;

  > span {
    margin: 0 !important;
  }

  @media (max-width: $breakpoint_l) {
    text-align: center;

    > span {
      margin: 0 auto !important;
    }

  }
}

@media (max-width: $breakpoint_l) {
  .contentOverlay {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .contentOverlay--left {
    padding: $spacing-4;
  }
  .contentOverlay--right {
    padding: $spacing-4;
  }
  
}

.title {
  color: $lights-high;
  white-space: pre-line;
  padding-bottom: $spacing-4;
  
  @media (max-width: $breakpoint_l) {
    text-align: center;
    white-space: normal;
  }

  @include text-h4
}

.description {
  color: $lights-medium;
  white-space: pre-line;
  @include text-body1Regular16;
  
  @media (max-width: $breakpoint_l) {
    text-align: center;
    white-space: normal;
  }
}