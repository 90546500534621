$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.downloadAppSection {
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  background: url("/images/download-bg.jpg") no-repeat right center;
  background-size: cover;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 145px;

  @media screen and (max-width: $breakpoint_l) {
    padding-left: 0;
    height: 600px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    height: 60vh;
  }

  .content {
    padding-top: $spacing-5;
    position: relative;
    z-index: 2;
    width: 50%;
    white-space: pre-line;

    @media screen and (max-width: $breakpoint_l) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      align-self: flex-start;
    }

    .title {
      padding-bottom: $spacing-5;
      white-space: normal;
      font-weight: bold;

      @include text-h2;

      @media (max-width: $breakpoint_l) {
        white-space: normal;
        white-space: pre-line;
      }

      @media screen and (min-width: $breakpoint_xxl) {
        @include text-h1;
      }
    }

    .subtitle {
      padding-bottom: $spacing-6;
      @include text-body1Regular18;
    }

    .storeButtons {
      display: flex;

      .storeLink img {
        width: 150px;
        height: auto;
      }
    }
  }

  .phoneContainer {
    position: absolute;
    top: 85%;
    right: 10%;
    transform: translateY(-50%);
    z-index: 1;

    @media (max-width: $breakpoint_l) {
      right: 0;
    }

    .phoneImage {
      width: 700px;
      height: auto;
      object-fit: contain;

      @media (max-width: $breakpoint_l) {
        height: 400px;
        width: 400px;
      }

      @media screen and (min-width: $breakpoint_xxl) {
        height: 70vh;
        width: 70vh;
      }
    }
  }
}
