$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.parentContainer {
  background-color: $background-main;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 100%;
  max-width: 1220px;
  box-sizing: border-box;
  border-radius: $radius-3;

  @media (max-width: $breakpoint_l) {
    max-width: 360px;
  }
  
}

.bannerContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 48px 64px;
  min-height: 500px;
  border-radius: $radius-3;
  border: 2px solid $accent-main;
  box-shadow: 0px 0px 50px 0px rgba(216, 164, 69, 0.50);
  overflow: hidden;
  box-sizing: border-box;

  @media (max-width: $breakpoint_l) {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: $spacing-6;
    min-height: auto;

  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60%;
  margin: 0;
  text-align: left;
  gap: $spacing-4;

  @media (max-width: $breakpoint_l) {
    max-width: 100%;
    padding-bottom: $spacing-6;
    
  }
}

.title {
  color: $lights-high;
  text-align: left;
  @include text-h4;

  @media (max-width: $breakpoint_l) {
    text-align: center;
  }
}

.description {
  color: $lights-medium;
  text-align: left;
  white-space: pre-line;
  @include text-body1Regular16;

  @media (max-width: $breakpoint_l) {
    text-align: center;
  }
}

.button {
  @media (max-width: $breakpoint_l) {
    align-self: center;
  }
}

.collageWrapper {
  position: absolute;
  left: 0;
  top: 23%;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: 80px;

  @media (max-width: $breakpoint_l) {
    position: static;
    top: auto;
  }
}

.collageImage {
  max-width: 1000px;

  @media (max-width: $breakpoint_l) {
    max-width: 480px;
  }
}
