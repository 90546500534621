$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  margin: auto;
  width: 360px;
  position: relative;
  z-index: 10;

  @media screen and (min-width: $breakpoint_m) {
    width: 600px;
  }

  @media screen and (min-width: $breakpoint_ml) {
    width: 800px;
  }
}

.buttonStyle {
  width: 25%;
  text-transform: uppercase;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  .customButtonTextStyle {
    @include text-h6;
  }
}

.searchBarSelect {
  @include text-body1Regular18;
  text-align: left;

  max-width: 265px;

  @media screen and (min-width: $breakpoint_m) {
    max-width: 505px;
  }

  @media screen and (min-width: $breakpoint_ml) {
    max-width: 694px;
  }
}
