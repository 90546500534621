$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.container {
  background-color: $background-main;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding:  40px 20px;
}

.columnContainer {
  display: flex;
  gap: 24px;
  
  @media (max-width: $breakpoint_l) {
    flex-direction: column;
  }
}

