$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.animationHomePageWrapper {
  height: 100vh;
}

.homePageWrapper {
  overflow: hidden;
}

.parallaxContainer {
  width: 100%;
  height: 100%;
  background-color: $background-main;
}

.mobileSafeArea {
  @include mobile-safe-area;

  @media screen and (min-width: $breakpoint_l) {
    padding-top: 0;
  }
}
